"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteVariableDeleted = exports.SiteVariableCreated = exports.ShowVariableCreated = void 0;
const typebox_1 = require("@sinclair/typebox");
const message_source_1 = require("../message-source");
const tracing_1 = require("../tracing");
/**
 * Message to emit when a new `site_variable` of type `show_variable` is
 * created, serving as both an "audit trail event" and a way to keep shows
 * in sync with variables.
 */
exports.ShowVariableCreated = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /** Set of values permitted in the show variable */
        enumValues: typebox_1.Type.Array(typebox_1.Type.String()),
        /** Initial value of the show variable */
        initialValue: typebox_1.Type.Optional(typebox_1.Type.String()),
        /** Name of the created variable */
        name: typebox_1.Type.String(),
        /** Identifier of the site where the variable was created */
        siteId: typebox_1.Type.String({ format: 'uuid' }),
        /** Identifier of the site_variable created */
        siteVariableId: typebox_1.Type.String({ format: 'uuid' }),
    }),
    'detail-type': typebox_1.Type.Literal('ShowVariable Created'),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
});
/**
 * Message to emit when a new `site_variable` is created, serving an "audit
 * trail event" and allowing other services to react to the creation of the
 * variable.
 */
exports.SiteVariableCreated = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /** Name of the created variable */
        name: typebox_1.Type.String(),
        /** Identifier of the site where the variable was created */
        siteId: typebox_1.Type.String({ format: 'uuid' }),
        /** Identifier of the site_variable created */
        siteVariableId: typebox_1.Type.String({ format: 'uuid' }),
    }),
    'detail-type': typebox_1.Type.Literal('SiteVariable Created'),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
});
/**
 * Message to emit when a `site_variable` is deleted, serving an "audit trail
 * event" and allowing other services to react to the deletion of the variable.
 */
exports.SiteVariableDeleted = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /** Name of the created variable */
        name: typebox_1.Type.String(),
        /** Identifier of the site where the variable was created */
        siteId: typebox_1.Type.String({ format: 'uuid' }),
        /** Identifier of the site_variable created */
        siteVariableId: typebox_1.Type.String({ format: 'uuid' }),
    }),
    'detail-type': typebox_1.Type.Literal('SiteVariable Deleted'),
    source: message_source_1.ApiSource,
    traceId: tracing_1.TraceId,
});
